import axios from "axios";
import router from '@/router'
export default {
  namespaced: true,
  state: {
    users: [],
    editUser: null,
    showUser: null,
    showModal: false,
    deleteUser: null,
    deleteModal: false,
    isLoading: false,
    isSaving: false,
  },
  getters: {  
    editUser: (state) => {
      return state.editUser;
    },
  },
  mutations: {
    loading(state, bool) {
      state.isLoading = bool;
    },
    saving(state, bool) {
      state.isSaving = bool;
    },
    APP_USERS(state, users) {
      state.users = users;
    },
    NEW_APP_USER(state, user) {
      state.users.push(user);
    },
    SHOW_MODAL(state, payload) {
      state.showModal = !payload.bool;
      state.showUser = payload.user;
    }, 
    DELETE_MODAL(state, payload) {
      state.deleteModal = !payload.bool;
      state.deleteUser = payload.user;
    },  
  },
  actions: {
    async saveUser(context, payload) {
      context.commit("saving", true);
      await axios
        .post("/user/register", payload)
        .then((response) => {
          if (response.response) {
            response.response.data.errors.username ? context.rootState.Notiflix.Notify.Failure(response.response.data.errors.username[0]) : null;
            response.response.data.errors.img ? context.rootState.Notiflix.Notify.Failure(response.response.data.errors.img[0]) : null;
            context.commit("saving", false);
          } else {
          context.commit("saving", false);
          context.commit("NEW_APP_USER", response.data.user);
          context.rootState.Notiflix.Notify.Success(response.data.message);
          router.push({ name: "Users" }).catch(()=>{});
        }
        })
        .catch((error) => {
          console.log(error);
          context.commit("saving", false);
        });
    }, 
     updateProfile(context, payload) {
      console.log(payload)
    }, 
    getUsers(context, token) {
      context.commit("loading", true);
      axios
        .get("/user/all", {
          Authorization: token,
        })
        .then((response) => {
          context.commit("APP_USERS", response.data.users);
          context.commit("loading", false);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateUser(context, payload) {
      context.commit("saving", true);
      axios
        .post("/user/update/" + payload.id, payload)
        .then((response) => {
          context.commit("saving", false);
          context.rootState.Notiflix.Notify.Info(response.data.message);
          router.push({ name: "Users" }).catch(()=>{});
        })
        .catch((error) => {
          console.log(error);
        });
    },
    destroyUser(context, payload) {
      context.commit("saving", true);   
      axios.post("/user/delete/" + payload.user.id).then((response) => {
        if(response.status === 202){
          context.commit("DELETE_MODAL", {bool: true, user: null });
          context.state.users = context.state.users.filter(user => user.id != payload.user.id); 
          context.commit("saving", false);   
          context.rootState.Notiflix.Notify.Warning(response.data.message);        
        }        
      }).catch((error) => {
        console.log(error);
        context.commit("saving", false);
        console.log('Server problem')
        context.commit("DELETE_MODAL", {bool: true, user: payload.user });
      });
    },
    editUser(context, payload) {
      context.state.editUser = context.state.users.filter(
        (user) => user.id == payload.user_id
      );
    },
  },
};
