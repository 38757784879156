import axios from "axios";
import router from "@/router";
export default {
  namespaced: true,
  state: {
    products: [],
    pagination: {},
    product_model_fetch_pagination: {},
    product_models: [],
    editProduct: null,
    showModal: false,
    showProduct: null,
    deleteProduct: null,
    deleteModal: false,
    isLoading: false,
    isSaving: false,
    isSearching: false,
    isFetching: false,
  },
  mutations: {
    loading(state, bool) {
      state.isLoading = bool;
    },
    searching(state, bool) {
      state.isSearching = bool;
    },
    fetching(state, bool) {
      state.isFetching = bool;
    },
    saving(state, bool) {
      state.isSaving = bool;
    },
    ALL_PRODUCTS(state, products) {
      state.products = products.data;
      state.pagination = products.pagination;
    },
    SET_PRODUCT_MODELS(state, payload) {
      if (payload.pagination.current === 1) {
        state.product_models = [];
      }
      payload.data.forEach((product_model) => {
        state.product_models.push(product_model);
      });
      state.product_model_fetch_pagination = payload.pagination;
    },
    SHOW_MODAL(state, payload) {
      state.showModal = !payload.bool;
      state.showProduct = payload.product;
    },
    NEW_PRODUCT(state, product) {
      state.products.push(product);
    },
    DELETE_MODAL(state, payload) {
      state.deleteModal = !payload.bool;
      state.deleteProduct = payload.product;
    },
  },
  actions: {
    fetch_search_items(context, keyword) {
      const check = keyword.trim().length;
      if (check) {
        const haveItems = context.state.product_models.filter((product_model) =>
          product_model.name
            .toLowerCase()
            .includes(keyword.trim().toLowerCase())
        );
        if (haveItems.length) {
          context.state.product_models = haveItems;
        } else {
          context.state.product_models = [];
          context.state.product_model_fetch_pagination = {};
          context.commit("searching", true);
          axios
            .get(`/product/model/search?keyword=${keyword.trim()}`)
            .then((response) => {
              context.state.product_models = response.data.product_model;
            })
            .then((response) => context.commit("searching", false));
        }
      } else {
        context.state.product_models = [];
        context.state.product_model_fetch_pagination = {};
        context.dispatch("products/getProductModels", 1, { root: true });
      }
    },
    async saveProduct(context, payload) {
      context.commit("saving", true);
      await axios
        .post("/product/store", payload)
        .then((response) => {
          if (response.response) {
            response.response.data.errors.name
              ? context.rootState.Notiflix.Notify.Failure(
                  response.response.data.errors.name[0]
                )
              : null;
            response.response.data.errors.img
              ? context.rootState.Notiflix.Notify.Failure(
                  response.response.data.errors.img[0]
                )
              : null;
            context.commit("saving", false);
          } else {
            context.commit("saving", false);
            context.commit("NEW_PRODUCT", response.data.product);
            context.rootState.Notiflix.Notify.Success(response.data.message);
            router.push({ name: "Products" }).catch(() => {});
          }
        })
        .catch((error) => {
          console.log(error);
          context.rootState.Notiflix.Notify.Failure(error.message);
          context.commit("saving", false);
        });
    },
    getProductModels(context, page = 1) {
      context.commit("fetching", true);
      context.commit("loading", false);
      if (page == 1) {
        context.state.product_model_fetch_pagination = {};
        context.state.product_models = [];
      }
      axios
        .get("/product/model/all?page=" + page)
        .then((response) => {
          context.commit("SET_PRODUCT_MODELS", response.data.product_model);
          if (
            response.data.product_model.pagination.current ===
            response.data.product_model.pagination.last
          ) {
       
            context.commit("fetching", false);
          }
          context.commit("loading", false);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProducts(context, page = 1) {
      context.commit("loading", true);
      context.state.pagination = {};
      axios
        .get("/product/all?page=" + page)
        .then((response) => {
          context.commit("ALL_PRODUCTS", response.data.product);
          context.commit("loading", false);
        })
        .catch((error) => {
          console.log(error);
          context.commit("loading", false);
        });
    },

    updateProduct(context, payload) {
      context.commit("saving", true);
      axios
        .post("/product/update/" + context.state.editProduct[0].id, payload)
        .then((response) => {
          context.commit("saving", false);
          context.rootState.Notiflix.Notify.Info(response.data.message);
          router.push({ name: "Products" }).catch(() => {});
        })
        .catch((error) => {
          console.log(error);
        });
    },
    destroyProduct(context, payload) {
      context.commit("saving", true);
      axios
        .post("/product/delete/" + payload.product.id)
        .then((response) => {
          if (response.status === 202) {
            context.commit("DELETE_MODAL", { bool: true, product: null });
            context.state.products = context.state.products.filter(
              (product) => product.id != payload.product.id
            );
            context.commit("saving", false);
            context.rootState.Notiflix.Notify.Warning(response.data.message);
          }
          if (response.data.status ? response.data.status == 406 : false) {
            context.commit("DELETE_MODAL", { bool: true, product: null });
            context.commit("saving", false);
            context.rootState.Notiflix.Notify.Failure(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("saving", false);
          console.log("Server problem");
          context.commit("DELETE_MODAL", {
            bool: true,
            product: payload.product,
          });
        });
    },
    editProduct(context, payload) {
      context.state.editProduct = context.state.products.filter(
        (product) => product.id == payload.product.id
      );
    },
  },
};
