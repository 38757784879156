import axios from "axios";
 
import router from '@/router'
export default {
  namespaced: true,
  state: {
    product_categories: [],
    pagination: {},
    editProductCategory: null,
    deleteProductCategory: null,
    computedProductCategories:null,
    deleteModal: false,
    showModal: false,
    showProductCategory: null,
    isLoading: false,
    isSaving: false,
  },
  mutations: {
    loading(state, bool) {
      state.isLoading = bool;
    },
    saving(state, bool) {
      state.isSaving = bool;
    },
    ALL_PRODUCT_CATEGORIES(state, product_categories) {
      state.product_categories = product_categories.data;
      state.pagination = product_categories.pagination
    },
    ALL_PRODUCT_CATEGORIES_LIST(state, product_categories) {
      state.computedProductCategories = product_categories; 
      state.product_categories = product_categories; 
    },
    NEW_PRODUCT_CATEGORY(state, product_category) {
      state.product_categories.push(product_category);
    },
    SHOW_MODAL(state, payload) {
      state.showModal = !payload.bool;
      state.showProductCategory = payload.product_category;
    },
    DELETE_MODAL(state, payload) {
      state.deleteModal = !payload.bool;
      state.deleteProductCategory = payload.product_category;
    },
  },
  actions: {
    computedProductCategories(context, payload) {
      context.state.computedProductCategories = context.state.product_categories.filter(
        (product_category) => product_category.brand.id == payload
      );
    },
    async saveProductCategory(context, payload) {
      context.commit("saving", true);
      await axios
        .post("/product/category/store", payload)
        .then((response) => {
          context.commit("saving", false);
          context.commit(
            "NEW_PRODUCT_CATEGORY",
            response.data.product_category
          );
          context.rootState.Notiflix.Notify.Success(response.data.message);
        })
        .catch((error) => {
          console.log(error);
          context.commit("saving", false);
        });
    },
    getProductCategories(context, page) {
      context.commit("loading", true);
      context.state.pagination = {};
      axios
        .get("/product/category/all?page=" + page)
        .then((response) => {
          context.commit(
            "ALL_PRODUCT_CATEGORIES",
            response.data.product_category
          );
          context.commit("loading", false);
          context.commit("product_models/loading", false, { root: true });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllProductCategories(context) {
      context.commit("loading", true);
      axios
        .get("/product/category/list")
        .then((response) => {
          context.commit(
            "ALL_PRODUCT_CATEGORIES_LIST",
            response.data.product_category
          );
          context.commit("loading", false);
          context.commit("product_models/loading", false, { root: true });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateProductCategory(context, payload) {
      context.commit("saving", true);
      axios
        .post(
          "/product/category/update/" + context.state.editProductCategory.id,
          payload
        )
        .then((response) => {
          context.commit("saving", false);
          context.rootState.Notiflix.Notify.Info(response.data.message);
          router.push({ name: "Product Categories" }).catch(()=>{});
        })
        .catch((error) => {
          console.log(error);
        });
    },
    destroyProductCategory(context, payload) {
      context.commit("saving", true);
      axios
        .post("/product/category/delete/" + payload.product_category.id)
        .then((response) => {
          if (response.status === 202) {
            context.commit("DELETE_MODAL", {
              bool: true,
              product_category: null,
            });
            context.state.product_categories = context.state.product_categories.filter(
              (product_category) =>
                product_category.id != payload.product_category.id
            );
            context.commit("saving", false);
            context.rootState.Notiflix.Notify.Warning(response.data.message);
          }
          if (response.data.status ? response.data.status == 406 : false) {
            context.commit("DELETE_MODAL", {
              bool: true,
              product_category: null,
            });
            context.commit("saving", false);
            context.rootState.Notiflix.Notify.Failure(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("saving", false);
          console.log("Server problem");
          context.commit("DELETE_MODAL", {
            bool: true,
            product_category: payload.product_category,
          });
        });
    },
    editProductCategory(context, product_category_id) {
      context.state.editProductCategory = context.state.product_categories.filter(
        (product_category) => product_category.id === product_category_id
      )[0];
      
    },
  },
};
