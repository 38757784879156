 import axios from 'axios'
 import {api} from './api' 
 import store from '@/store/index.js';
import router from './router';
 axios.defaults.baseURL = api.url;
 axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token') || store.state.access_token;
 axios.interceptors.response.use(response => {
    return response;
 }, error => {
   if (error.response.status === 401) {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    localStorage.removeItem("user_permissions");
    store.state.access_token = null;
    store.state.login_message = null;
    store.state.userPermissions = null;
    store.state.current_user = null;
    router.replace({ name: "Login" }).catch(() => {});
   }
   return error;
 });