import axios from "axios"; 
export default {
  namespaced: true,
  state: {
    suppliers: [],
    pagination: {},
    editSupplier: null,
    deleteSupplier: null,
    deleteModal: false,
    showSupplier: null,
    showModal: false,
    isLoading: false,
    isSaving: false,
  },  
  mutations: {
    loading(state, bool) {
      state.isLoading = bool;
    },
    saving(state, bool) {
      state.isSaving = bool;
    },
    ALL_SUPPLIERS(state, suppliers) {
      state.suppliers = suppliers.data;
      state.pagination = suppliers.pagination;
    },
    NEW_SUPPLIER(state, supplier) {
      state.suppliers.push(supplier);
    },
    DELETE_MODAL(state, payload) {
      state.deleteModal = !payload.bool;
      state.deleteSupplier = payload.supplier;
    },  
    SHOW_MODAL(state, payload) {
      state.showModal = !payload.bool;
      state.showSupplier = payload.supplier;
    },  
  },
  actions: {
    async saveSupplier(context, payload) {
      context.commit("saving", true);
      await axios
        .post("/supplier/store", payload)
        .then((response) => {
          context.commit("saving", false);
          context.commit("NEW_SUPPLIER", response.data.supplier);
          context.rootState.Notiflix.Notify.Success(response.data.message);
        })
        .catch((error) => {
          console.log(error);
          context.commit("saving", false);
        });
    },     
    getSuppliers(context, page) {
      context.commit("loading", true);
      context.state.pagination = {};
      axios
        .get("/supplier/all?page=" + page)
        .then((response) => {
          context.commit("ALL_SUPPLIERS", response.data.supplier);
          context.commit("loading", false);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateSupplier(context, payload) {
      context.commit("saving", true);
      axios
        .post("/supplier/update/" + payload.id, payload)
        .then((response) => {
          context.commit("saving", false);
          context.rootState.Notiflix.Notify.Info(response.data.message);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    destroySupplier(context, payload) {
      context.commit("saving", true);   
      axios.post("/supplier/delete/" + payload.supplier.id).then((response) => {
        if(response.status === 202){
          context.commit("DELETE_MODAL", {bool: true, supplier: null });
          context.state.suppliers = context.state.suppliers.filter(supplier => supplier.id != payload.supplier.id); 
          context.commit("saving", false);     
          context.rootState.Notiflix.Notify.Warning(response.data.message);      
        }        
      }).catch((error) => {
        console.log(error);
        context.commit("saving", false);
        console.log('Server problem')
        context.commit("DELETE_MODAL", {bool: true, supplier: payload.supplier });
      });
    },
    editSupplier(context, payload) {
      context.state.editSupplier = context.state.suppliers.filter(
        (supplier) => supplier.id == payload.supplier_id
      );
    },
  },
};
