 import axios from 'axios'
 
 export default {
        namespaced:true,
        state:{
         permissions: [],
         isLoading: false,
         isSaving: false,
        },
        getters: {
         permissions : state => {
           return state.permissions
         } 
        },
        mutations: {
         loading(state, bool) {
           state.isLoading = bool;
         },
         saving(state, bool) {
           state.isSaving = bool;
         },
         app_permissions(state, permissions) {
           state.permissions = permissions 
        },
        },
        actions: {
         getPermissions(context, token) {
           if(context.state.permissions.length == 0){
             context.commit("loading", true);
           }    
           axios
             .get("/permissions", {
               Authorization: token,
             })
             .then((response) => {
               context.commit("app_permissions", response.data.permissions);        
               context.commit("loading", false);
             })
             .catch((error) => {
               console.log(error);
             });
         },
         async saveRole(context, payload) {
           context.commit("saving", true);
           await axios
           .post("/role/store", {
             name: payload.role_name,
             role_permission_id: payload.permissions,
           })
           .then((response) => {
             context.commit("saving", false);
             context.commit("roles/ADD_NEW_ROLE", response.data.role, { root: true });   
             context.rootState.Notiflix.Notify.Success(response.data.message);      
           });
               
           },
        }
   
 
}