import axios from "axios";
 
export default {
  namespaced: true,
  state: {
    customers: [],
    pagination: {},
    editCustomer: null,
    deleteCustomer: null,
    deleteModal: false,
    showCustomer: null,
    showModal: false,
    isLoading: false,
    isSaving: false,
  },  
  mutations: {
    loading(state, bool) {
      state.isLoading = bool;
    },
    saving(state, bool) {
      state.isSaving = bool;
    },
    ALL_CUSTOMERS(state, customers) {
      state.customers = customers.data;
      state.pagination = customers.pagination;
    },
    NEW_CUSTOMER(state, customers) {
      state.customers.push(customers);
    },
    DELETE_MODAL(state, payload) {
      state.deleteModal = !payload.bool;
      state.deleteCustomer = payload.customer;
    },  
    SHOW_MODAL(state, payload) {
      state.showModal = !payload.bool;
      state.showCustomer = payload.customer;
    },  
  },
  actions: {
    async saveCustomer(context, payload) {
      context.commit("saving", true);
      await axios
        .post("/customer/store", payload)
        .then((response) => {
          context.commit("saving", false);
          context.commit("NEW_CUSTOMER", response.data.customer);
          Notiflix.Notify.Success('Customer Added!');
        })
        .catch((error) => {
          console.log(error);
          context.commit("saving", false);
        });
    },     
    getCustomers(context, page) {
      console.log(page)
      context.commit("loading", true);
      context.state.pagination = {};
      axios
        .get("/customer/all?page=" + page)
        .then((response) => {
          context.commit("ALL_CUSTOMERS", response.data.customer);
          context.commit("loading", false);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateCustomer(context, payload) {
      context.commit("saving", true);
      axios
        .post("/customer/update/" + payload.id, payload)
        .then((response) => {
          context.commit("saving", false); 
          Notiflix.Notify.Info('Customer Updated!');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    destroyCustomer(context, payload) {
      context.commit("saving", true);   
      axios.post("/customer/delete/" + payload.customer.id).then((response) => {
        if(response.status === 202){
          context.commit("DELETE_MODAL", {bool: true, customer: null });
          context.state.customers = context.state.customers.filter(customer => customer.id != payload.customer.id); 
          context.commit("saving", false);       
          Notiflix.Notify.Warning('Customer Deleted!');    
        }        
      }).catch((error) => {
        console.log(error);
        context.commit("saving", false);
        console.log('Server problem')
        context.commit("DELETE_MODAL", {bool: true, customer: payload.customer });
      });
    },
    editCustomer(context, payload) {
      context.state.editCustomer = context.state.customers.filter(
        (customer) => customer.id == payload.customer_id
      );
    },
  },
};
