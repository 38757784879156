import axios from "axios";
import router from "@/router";
export default {
  namespaced: true,
  state: {
    stock_products: [],
    products: [],
    pagination: {},
    product_fetch_pagination: {},
    isLoading: false,
    isSaving: false,
    isSearching: false,
    isFetching: false,
  },
  mutations: {
    loading(state, bool) {
      state.isLoading = bool;
    },
    searching(state, bool) {
      state.isSearching = bool;
    },
    fetching(state, bool) {
      state.isFetching = bool;
    },
    saving(state, bool) {
      state.isSaving = bool;
    },
    ALL_STOCKS(state, stocks) {
      state.stock_products = stocks.data;
      state.pagination = stocks.pagination;
    },
    SET_PRODUCTS(state, payload) {
      if (payload.pagination.current === 1) {
        state.products = [];
      }
      payload.data.forEach((product) => {
        state.products.push(product);
      });
      state.product_fetch_pagination = payload.pagination;
    },
  },
  actions: {
    fetch_search_items(context, keyword) {
      const check = keyword.trim().length;
      if (check) {
        const haveItems = context.state.products.filter((product) =>
          product.name.toLowerCase().includes(keyword.trim().toLowerCase())
        );
        if (haveItems.length) {
          context.state.products = haveItems;
        } else {
          context.state.products = [];
          context.state.product_fetch_pagination = {};
          context.commit("searching", true);
          axios
            .get(`/product/search?keyword=${keyword.trim()}`)
            .then((response) => {
              context.state.products = response.data.search_result;
            })
            .then((response) => context.commit("searching", false));
        }
      } else {
        context.state.products = [];
        context.state.product_fetch_pagination = {};
        context.dispatch("stocks/getProducts", 1, { root: true });
      }
    },
    async saveStock(context, payload) {
      context.commit("saving", true);
      if(payload.purchasing_codes){
      await axios
        .post("/stock/barcode/store", {
          product_id: payload.product.id,
          purchasing_codes: payload.purchasing_codes,
          stocks: payload.stocks,
          purchase_price: payload.purchase_price,
        })
        .then((response) => {
          context.commit("saving", false);
          context.rootState.Notiflix.Notify.Success(response.data.message);
          router.push({ name: "Stocks" }).catch(() => {});
        })
        .catch((error) => {
          console.log(error);
          context.rootState.Notiflix.Notify.Failure(error.message);
          context.commit("saving", false);
        });
      }
        else {
      await axios
        .post("/stock/bulk/store", {
          product_id: payload.product.id, 
          stocks: payload.stocks,
          purchase_price: payload.purchase_price,
        })
        .then((response) => {
          context.commit("saving", false);
          context.rootState.Notiflix.Notify.Success(response.data.message);
          router.push({ name: "Stocks" }).catch(() => {});
        })
        .catch((error) => {
          console.log(error);
          context.rootState.Notiflix.Notify.Failure(error.message);
          context.commit("saving", false);
        });
      }
    },
    getProducts(context, page = 1) {
      context.commit("fetching", true);
      context.commit("loading", false);
      if (page == 1) {
        context.state.product_fetch_pagination = {};
        context.state.products = [];
      }
      axios
        .get("/product/all?page=" + page)
        .then((response) => {
          context.commit("SET_PRODUCTS", response.data.product);
          if (
            response.data.product.pagination.current ===
            response.data.product.pagination.last
          ) {
            context.commit("fetching", false);
          }
          context.commit("loading", false);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getStocks(context, page = 1) {
      context.commit("loading", true);
      context.state.pagination = {};
      axios
        .get("/stock/all?page=" + page)
        .then((response) => {
          context.commit("ALL_STOCKS", response.data.product);
          context.commit("loading", false);
        })
        .catch((error) => {
          console.log(error);
          context.commit("loading", false);
        });
    },
  },
};
