import axios from "axios"; 
export default {
  namespaced: true,
  state: {
    product_groups: [],
    pagination: {},
    editProductGroup: null,
    deleteProductGroup: null,
    deleteModal: false,
    isLoading: false,
    isSaving: false,
  },  
  mutations: {
    loading(state, bool) {
      state.isLoading = bool;
    },
    saving(state, bool) {
      state.isSaving = bool;
    },
    ALL_PRODUCT_GROUPS(state, product_groups) {
      state.product_groups = product_groups.data;
      state.pagination = product_groups.pagination
    },
    ALL_PRODUCT_GROUPS_LIST(state, product_groups) {
      state.product_groups = product_groups;      
    },
    NEW_PRODUCT_GROUP(state, product_groups) {
      state.product_groups.push(product_groups);
    },
    DELETE_MODAL(state, payload) {
      state.deleteModal = !payload.bool;
      state.deleteProductGroup = payload.product_group;
    },  
 
  },
  actions: {
    async saveProductGroup(context, payload) {
      context.commit("saving", true);
      await axios
        .post("/product/group/store", payload)
        .then((response) => {
          context.commit("saving", false);
          context.commit("NEW_PRODUCT_GROUP", response.data.product_group);        
          context.rootState.Notiflix.Notify.Success('Product Group Added!');
        })
        .catch((error) => {
          console.log(error);
          context.commit("saving", false);
        });
    },     
    getProductGroups(context, page) {
      context.commit("loading", true);
      context.state.pagination = {};
      axios
        .get("/product/group/all?page=" + page)
        .then((response) => {
          context.commit("ALL_PRODUCT_GROUPS", response.data.product_group);
          context.commit("loading", false);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllProductGroups(context) {
      context.commit("loading", true);
      axios
        .get("/product/group/list")
        .then((response) => {
          context.commit("ALL_PRODUCT_GROUPS_LIST", response.data.product_group);
          context.commit("loading", false);
          context.commit("brands/loading", false, { root: true });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateProductGroup(context, payload) {
      context.commit("saving", true);
      axios
        .post("/product/group/update/" + payload.id, payload)
        .then((response) => {
          context.commit("saving", false); 
          context.rootState.Notiflix.Notify.Info('Product Group Updated!');
          router.push({ name: "Product Groups" }).catch(()=>{});
        })
        .catch((error) => {
          console.log(error);
        });
    },
    destroyProductGroup(context, payload) {
      context.commit("saving", true);   
      axios.post("/product/group/delete/" + payload.product_group.id).then((response) => {
        if(response.status === 202){
          context.commit("DELETE_MODAL", {bool: true, product_group: null });
          context.state.product_groups = context.state.product_groups.filter(product_group => product_group.id != payload.product_group.id); 
          context.commit("saving", false);     
          context.rootState.Notiflix.Notify.Warning('Product Group Deleted!');
        }        
      }).catch((error) => {
        console.log(error);
        context.commit("saving", false);
        console.log('Server problem')
        context.commit("DELETE_MODAL", {bool: true, product_group: payload.product_group });
      });
    },
    editProductGroup(context, payload) {
      context.state.editProductGroup = context.state.product_groups.filter(
        (product_group) => product_group.id == payload.product_group_id
      );
    },
  },
};
