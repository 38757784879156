import axios from "axios";

export default {
  namespaced: true,
  state: {
    roles: [],
    editRole: null,
    deleteRole: null,
    deleteModal: false,
    isLoading: false,
    isSaving: false,
 
  },
  
  mutations: {
    loading(state, bool) {
      state.isLoading = bool;
    },
    saving(state, bool) {
      state.isSaving = bool;
    },
    APP_ROLES(state, roles) {
      state.roles = roles;
    },
    ADD_NEW_ROLE(state, payload) {
      state.roles.push(payload)
    },  
    DELETE_MODAL(state, payload) {
      state.deleteModal = !payload.bool;
      state.deleteRole = payload.role;
    },  
    UPDATE_ROLE(state, role) { 
      var foundIndex = state.roles.findIndex((x) => x.id == role.id);
      state.roles[foundIndex] = role; 
    },
  },
  actions: {
    getRoles(context) {
      if (context.state.roles) {
        context.commit("loading", true);
      }
      axios
        .get("/roles")
        .then((response) => {
          context.commit("APP_ROLES", response.data.roles);
          context.commit("loading", false);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateRole(context, payload) {
      context.commit("saving", true);
      axios
        .post("/role/update/" + payload.role.id, {
          Authorization: payload.Authorization,
          name: payload.role.name,
          role_permission_id: payload.role_permission_id,
        })
        .then((response) => {
          if(response.data.status === 403){
            this.$store.dispatch('logout')
          }
          context.commit("saving", false);
          context.commit("UPDATE_ROLE", response.data.role);
          context.rootState.Notiflix.Notify.Info('Role Updated!');
        })
        .catch((error) => {
          console.log(error);
          context.commit("saving", false);
        });
        
    },
    destroyRole(context, payload) {
      context.commit("saving", true);   
      axios.post("/role/delete/" + payload.role.id).then((response) => {
        if(response.status === 202){
          context.commit("DELETE_MODAL", {bool: true, role: null });
          context.state.roles = context.state.roles.filter(role => role.id != payload.role.id); 
          context.commit("saving", false);        
          context.rootState.Notiflix.Notify.Warning('Role Deleted!');   
        }
        
      }).catch((error) => {
        console.log(error);
        context.commit("saving", false);
        console.log('Server problem')
        context.commit("DELETE_MODAL", {bool: true, role: payload.role });
      });
    },
    editRole(context, payload) {
      context.state.editRole = context.state.roles.filter(
        (role) => role.id == payload.role_id
      );
    },
  },
};
