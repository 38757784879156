import 'core-js/stable'
import Vue from 'vue' 
import VueLodash from 'vue-lodash'
import lodash from 'lodash' 
Vue.use(require('vue-moment'));
Vue.use(VueLodash, { lodash: lodash })
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import vSelect from 'vue-select'
import { iconsSet as icons } from './assets/icons/icons.js' 
import store from './store/index.js'
import './axios' 

Vue.component('v-select', vSelect)
Vue.config.performance = true
Vue.use(CoreuiVue)    
Vue.prototype.$log = console.log.bind(console)   

Vue.filter('capitalizeTheFirstLetterOfEachWord', function(words) {
  var separateWord = words.toLowerCase().split(' ');
  for (var i = 0; i < separateWord.length; i++) {
     separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
     separateWord[i].substring(1);
  }
  return separateWord.join(' ');
})


new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
