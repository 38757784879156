import axios from "axios";
import router from '@/router'
 export default {
  namespaced: true,
  state: {
    product_models: [],
    pagination: {},
    editProductModel: null,
    deleteProductModel: null,
    deleteModal: false,
    showModal: false,
    showProductModel: null,
    isLoading: false,
    isSaving: false,
  },
  mutations: {
    loading(state, bool) {
      state.isLoading = bool;
    },
    saving(state, bool) {
      state.isSaving = bool;
    },
    ALL_PRODUCT_MODELS(state, product_models) {
      state.product_models = product_models.data;
      state.pagination = product_models.pagination
    },
    NEW_PRODUCT_MODEL(state, product_model) {
      state.product_models.push(product_model);
    },
    SHOW_MODAL(state, payload) {
      state.showModal = !payload.bool;
      state.showProductModel = payload.product_model;
    },
    DELETE_MODAL(state, payload) {
      state.deleteModal = !payload.bool;
      state.deleteProductModel = payload.product_model;
    },
  },
  actions: {
    async saveProductModel(context, payload) {
      context.commit("saving", true);
      await axios
        .post("/product/model/store", payload)
        .then((response) => {
          if (response.response) {
            response.response.data.errors.name ? context.rootState.Notiflix.Notify.Failure(response.response.data.errors.name[0]) : null;
            context.commit("saving", false);
          } else {
          context.commit("saving", false);
          context.commit(
            "NEW_PRODUCT_MODEL",
            response.data.product_model
          );
          context.rootState.Notiflix.Notify.Success(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("saving", false);
        });
    },
    getProductModels(context, page = 1) {
      context.commit("loading", true);
      context.state.pagination = {};
      axios
        .get("/product/model/all?page=" + page)
        .then((response) => {
          context.commit(
            "ALL_PRODUCT_MODELS",
            response.data.product_model
          );
          context.commit("loading", false);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateProductModel(context, payload) {
      context.commit("saving", true);
      axios
        .post(
          "/product/model/update/" + payload.id,
          payload
        )
        .then((response) => {
          context.commit("saving", false);
          context.rootState.Notiflix.Notify.Info(response.data.message);
          router.push({ name: "Product Models" }).catch(()=>{});
        })
        .catch((error) => {
          console.log(error);
        });
    },
    destroyProductModel(context, payload) {
      context.commit("saving", true);
      axios
        .post("/product/model/delete/" + payload.product_model.id)
        .then((response) => {
          if (response.status === 202) {
            context.commit("DELETE_MODAL", {
              bool: true,
              product_model: null,
            });
            context.state.product_models = context.state.product_models.filter(
              (product_model) =>
                product_model.id != payload.product_model.id
            );
            context.commit("saving", false);
            context.rootState.Notiflix.Notify.Warning(response.data.message);
          }
          if (response.data.status ? response.data.status == 406 : false) {
            context.commit("DELETE_MODAL", {
              bool: true,
              product_model: null,
            });
            context.commit("saving", false);
            context.rootState.Notiflix.Notify.Failure(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("saving", false);
          console.log("Server problem");
          context.commit("DELETE_MODAL", {
            bool: true,
            product_model: payload.product_model,
          });
        });
    },
    editProductModel(context, payload) {
      context.state.editProductModel = context.state.product_models.filter(
        (product_model) => product_model.id == payload.product_model_id
      );
    },
  },
};
