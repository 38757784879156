import Vue from "vue";
import Router from "vue-router";

import store from "@/store/index.js";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");

// Views - Pages
const Login = () => import("@/views/pages/Login");
const RoleCreate = () => import("@/views/Role/RoleCreate");
const RoleEdit = () => import("@/views/Role/RoleEdit");
const Roles = () => import("@/views/Role/Roles");

const UserProfile = () => import("@/views/User/Profile");
const Users = () => import("@/views/User/Users");
const UserCreate = () => import("@/views/User/UserCreate");
const UserEdit = () => import("@/views/User/UserEdit");

const Suppliers = () => import("@/views/Supplier/Suppliers");
const SupplierCreate = () => import("@/views/Supplier/SupplierCreate");
const SupplierEdit = () => import("@/views/Supplier/SupplierEdit");

const Customers = () => import("@/views/Customer/Customers");
const CustomerCreate = () => import("@/views/Customer/CustomerCreate");
const CustomerEdit = () => import("@/views/Customer/CustomerEdit");

const Brands = () => import("@/views/Brand/Brands");
const BrandCreate = () => import("@/views/Brand/BrandCreate");
const BrandEdit = () => import("@/views/Brand/BrandEdit");

const Products = () => import("@/views/Product/Products");
const ProductCreate = () => import("@/views/Product/ProductCreate");
const ProductEdit = () => import("@/views/Product/ProductEdit");

const ProductGroups = () => import("@/views/ProductGroup/ProductGroups");
const ProductGroupCreate = () =>
  import("@/views/ProductGroup/ProductGroupCreate");
const ProductGroupEdit = () => import("@/views/ProductGroup/ProductGroupEdit");

const ProductCategories = () => import("@/views/ProductCategory/ProductCategories");
const ProductCategoryCreate = () =>
  import("@/views/ProductCategory/ProductCategoryCreate");
const ProductCategoryEdit = () => import("@/views/ProductCategory/ProductCategoryEdit");

const ProductModels = () => import("@/views/ProductModel/ProductModels");
const ProductModelCreate = () =>
  import("@/views/ProductModel/ProductModelCreate");
const ProductModelEdit = () => import("@/views/ProductModel/ProductModelEdit");

const Stocks = () => import("@/views/Stock/Stocks");
const StockAddBarcode = () => import("@/views/Stock/StockAddBarcode");
const StockAdd = () => import("@/views/Stock/StockAdd");
 

import { ACLRoutePermissions } from "@/routepermissions/ACLRoutePermissions";

Vue.use(Router);

const router = new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/",
      name: "Dashboard",
      component: TheContainer,
      beforeEnter: (to, from, next) => {
        if (!store.state.access_token) {
          router.replace({ name: "Login" }).catch(() => {});
        } else {
          next();
        }
      },
      children: [
        {
          path: "dashboard",
          component: Dashboard,
        },
      ],
    },
    {
      path: "/",
      name: "Home",
      component: TheContainer,
      children: [
        {
          path: "/role/create",
          name: "Create Role",
          component: RoleCreate,
        },
        {
          path: "/role/edit",
          name: "Edit Role",
          component: RoleEdit,
        },
        {
          path: "/roles",
          name: "All Roles",
          component: Roles,
        },
        {
          path: "/users",
          name: "Users",
          component: Users,
        },
        {
          path: "/user/create",
          name: "Create User",
          component: UserCreate,
        },
        {
          path: "/user/edit",
          name: "Edit User",
          component: UserEdit,
        },
        {
          path: "/profile",
          name: "User Profile",
          component: UserProfile,
        },
        {
          path: "/suppliers",
          name: "Suppliers",
          component: Suppliers,
        },
        {
          path: "/supplier/create",
          name: "Create Supplier",
          component: SupplierCreate,
        },
        {
          path: "/supplier/edit",
          name: "Edit Supplier",
          component: SupplierEdit,
        },
        {
          path: "/customers",
          name: "Customers",
          component: Customers,
        },
        {
          path: "/customer/create",
          name: "Create Customer",
          component: CustomerCreate,
        },
        {
          path: "/customer/edit",
          name: "Edit Customer",
          component: CustomerEdit,
        },
        {
          path: "/products",
          name: "Products",
          component: Products,
        },
        {
          path: "/products/create",
          name: "Create Product",
          component: ProductCreate,
        },
        {
          path: "/product/edit",
          name: "Edit Product",
          component: ProductEdit,
        },
        {
          path: "/brands",
          name: "Brands",
          component: Brands,
        },
        {
          path: "/brand/create",
          name: "Create Brand",
          component: BrandCreate,
        },
        {
          path: "/brand/edit",
          name: "Edit Brand",
          component: BrandEdit,
        },
        {
          path: "/product/groups",
          name: "Product Groups",
          component: ProductGroups,
        },
        {
          path: "/product/group/create",
          name: "Create Product Group",
          component: ProductGroupCreate,
        },
        {
          path: "/product/group/edit",
          name: "Edit Product Group",
          component: ProductGroupEdit,
        },
        {
          path: "/product/categories",
          name: "Product Categories",
          component: ProductCategories,
        },
        {
          path: "/product/category/create",
          name: "Create Product Category",
          component: ProductCategoryCreate,
        },
        {
          path: "/product/category/edit",
          name: "Edit Product Category",
          component: ProductCategoryEdit,
        },
        {
          path: "/product/models",
          name: "Product Models",
          component: ProductModels,
        },
        {
          path: "/product/model/create",
          name: "Create Product Model",
          component: ProductModelCreate,
        },
        {
          path: "/product/model/edit",
          name: "Edit Product Model",
          component: ProductModelEdit,
        },
        {
          path: "/stocks",
          name: "Stocks",
          component: Stocks,
        },
        {
          path: "/stock/add/barcode",
          name: "Product Stock (Barcode)",
          component: StockAddBarcode,
        },
        {
          path: "/stock/add/bulk",
          name: "Product Stock (Bulk)",
          component: StockAdd,
        },       
      ],
    },
  ];
}


export default router;
