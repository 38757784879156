import axios from "axios";
import router from "@/router";
export default {
  namespaced: true,
  state: {
    brands: [],
    pagination: {},
    computedBrands: [],
    editBrand: null,
    showModal: false,
    showBrand: null,
    deleteBrand: null,
    deleteModal: false,
    isLoading: false,
    isSaving: false,
  },
  mutations: {
    loading(state, bool) {
      state.isLoading = bool;
    },
    saving(state, bool) {
      state.isSaving = bool;
    },
    ALL_BRANDS(state, brands) {
      state.brands = brands.data;
      state.pagination = brands.pagination;
    },
    ALL_BRANDS_LIST(state, brands) {
      state.computedBrands = brands;
      state.brands = brands;
    },
    SHOW_MODAL(state, payload) {
      state.showModal = !payload.bool;
      state.showBrand = payload.brand;
    },
    NEW_BRAND(state, brand) {
      state.brands.push(brand);
    },
    DELETE_MODAL(state, payload) {
      state.deleteModal = !payload.bool;
      state.deleteBrand = payload.brand;
    },
  },
  actions: {
    async saveBrand(context, payload) {
      context.commit("saving", true);
      await axios
        .post("/brand/store", payload)
        .then((response) => {
          if (response.response) {
            response.response.data.errors.name
              ? context.rootState.Notiflix.Notify.Failure(
                  response.response.data.errors.name[0]
                )
              : null;
            response.response.data.errors.img
              ? context.rootState.Notiflix.Notify.Failure(
                  response.response.data.errors.img[0]
                )
              : null;
            context.commit("saving", false);
          } else {
            context.commit("saving", false);
            context.commit("NEW_BRAND", response.data.brand);
            context.rootState.Notiflix.Notify.Success(response.data.message);
            router.push({ name: "Brands" }).catch(() => {});
          }
        })
        .catch((error) => {
          console.log(error);
          context.rootState.Notiflix.Notify.Failure(error.message);
          context.commit("saving", false);
        });
    },
    getBrands(context, page) {
      context.commit("loading", true);
      context.state.pagination = {};
      axios
        .get("/brand/all?page=" + page)
        .then((response) => {
          context.commit("ALL_BRANDS", response.data.brand);
          context.commit("loading", false);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllBrands(context) {
      context.commit("loading", true);
      axios
        .get("/brand/list")
        .then((response) => {
          context.commit("ALL_BRANDS_LIST", response.data.brand);
          context.commit("loading", false);
          context.commit("product_categories/loading", false, { root: true });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    computedBrands(context, payload) {
      context.state.computedBrands = context.state.brands.filter(
        (brand) => brand.product_group.id == payload
      );
    },
    updateBrand(context, payload) {
      context.commit("saving", true);
      axios
        .post("/brand/update/" + context.state.editBrand[0].id, payload)
        .then((response) => {
          context.commit("saving", false);
          context.rootState.Notiflix.Notify.Info(response.data.message);
          router.push({ name: "Brands" }).catch(() => {});
        })
        .catch((error) => {
          console.log(error);
        });
    },
    destroyBrand(context, payload) {
      context.commit("saving", true);
      axios
        .post("/brand/delete/" + payload.brand.id)
        .then((response) => {
          if (response.status === 202) {
            context.commit("DELETE_MODAL", { bool: true, brand: null });
            context.state.brands = context.state.brands.filter(
              (brand) => brand.id != payload.brand.id
            );
            context.commit("saving", false);
            context.rootState.Notiflix.Notify.Warning(response.data.message);
          }
          if (response.data.status ? response.data.status == 406 : false) {
            context.commit("DELETE_MODAL", { bool: true, brand: null });
            context.commit("saving", false);
            context.rootState.Notiflix.Notify.Failure(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("saving", false);
          console.log("Server problem");
          context.commit("DELETE_MODAL", { bool: true, brand: payload.brand });
        });
    },
    editBrand(context, payload) {
      context.state.editBrand = context.state.brands.filter(
        (brand) => brand.id == payload.brand_id
      );
    },
  },
};
