import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import Notiflix from "notiflix";
import router from "../router";
import permissionsModule from "./modules/permission/permissions";
import rolesModule from "./modules/role/roles";
import usersModule from "./modules/user/users";
import suppliers from "./modules/supplier/suppliers";
import customersModule from "./modules/customer/customers";
import productgroupsModule from "./modules/productgroup/product_groups";
import brands from "./modules/brand/brands";
import product_categories from "./modules/product_category/product_categories";
import product_models from "./modules/product_model/product_models";
import products from "./modules/product/products";
import stocks from "./modules/stock/stocks";
Notiflix.Notify.Init({
  position: "right-top",
  backOverlay: false,
  backOverlayColor: "rgba(0,0,0,0.5)",
  clickToClose: true,
  cssAnimation: true,
  cssAnimationDuration: 400,
  cssAnimationStyle: "from-bottom",
});
Vue.use(Vuex);
const modules = {
  permissions: permissionsModule,
  roles: rolesModule,
  users: usersModule,
  suppliers: suppliers,
  products: products,
  product_categories: product_categories,
  product_models: product_models,
  brands: brands,
  customers: customersModule,
  product_groups: productgroupsModule,
  stocks: stocks,
};
const state = {
  Notiflix: Notiflix,
  access_token: localStorage.getItem("access_token") || null,
  current_user: JSON.parse(localStorage.getItem("user")) || null,
  userPermissions: JSON.parse(localStorage.getItem("user_permissions")) || null,
  sidebarShow: "responsive",
  sidebarMinimize: false,
  message: null,
  login_message: null,
  status: null,
  isLoading: false,
};

const mutations = {
  getToken(state, token) {
    state.access_token = token;
  },
  loading(state, bool) {
    state.isLoading = bool;
  },
  setMessage(state, message) {
    state.message = message;
  },
  setStatus(state, status) {
    state.status = status;
  },
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  SET_USER_PERMISSIONS(state, permissions) {
    state.userPermissions = permissions;
  },
};
const actions = {
  async getToken(context, credentials) {
    await axios
      .post("/login", credentials)
      .then((response) => {
        const token = response.data.token;
        localStorage.setItem("access_token", token);
        context.state.current_user = response.data.user;
        localStorage.setItem("user", JSON.stringify(response.data.user));
        context.commit("SET_USER_PERMISSIONS", response.data.permission);
        localStorage.setItem(
          "user_permissions",
          JSON.stringify(response.data.permission)
        );
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("access_token");
        context.commit("getToken", token);
        Notiflix.Notify.Info(
          `Welcome, ${context.state.current_user.first_name +
            " " +
            context.state.current_user.last_name}!`
        );
        router.push({ name: "Dashboard" });
      })
      .catch(function(error) {
        if (error.response && error.response.status === 401) {
          context.state.login_message = error.response.data.message;
        }
      });
  },
  logout(context) {
    if (localStorage.getItem("access_token") == null) {
      return;
    }
    axios.post("/logout", {
      Authorization: "Bearer " + context.state.access_token,
    });
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    localStorage.removeItem("user_permissions");
    context.state.access_token = null;
    context.state.login_message = null;
    context.commit("SET_USER_PERMISSIONS", []);
    context.state.current_user = null;
    router.go({ name: "Login" });
  },
};
export default new Vuex.Store({
  modules,
  state,
  mutations,
  actions,
});
